
const state = {
    // apiEndpoint: "http://127.0.0.1:8000/",
    // payment_success: "http://127.0.0.1:8000/api/enquiryPaymentStatusUpdate",
    // payment_failed: "http://localhost:8081/#/payment_failed/",
   
   apiEndpoint: "https://api.skilledone.ca/",
   payment_success: "https://api.skilledone.ca/api/enquiryPaymentStatusUpdate",
   payment_failed: "https://skilledone.ca/#/payment_failed/",

//    apiEndpoint: "https://api.skilledone.in/",
//    payment_success: "https://api.skilledone.in/api/enquiryPaymentStatusUpdate",
//    payment_failed: "https://skilledone.in/#/payment_failed/",
    
    // apiEndpoint:  "http://api.takecaremyhome.com/",
    // payment_success: "http://api.takecaremyhome.com/api/enquiryPaymentStatusUpdate",
    // payment_failed: "http://app.takecaremyhome.com/#/payment_failed/",

   apiImage: "https://fileserver.skilledone.ca/",
// apiImage: "http://fileserver1.takecaremyhome.com/"
    
}
export default state